import axios from "axios";

// const endpoint = "https://tokenservicebackend.frp.t05.sg/";
// const endpoint = "http://localhost:2022";
// const endpoint = `https://staging.api.omt.sg`;
const endpoint = `https://api.omt.sg`;

const instance = axios.create({ baseURL: endpoint });

const API = (method, url, data, options) => {
  return instance({ method, url, data, withCredentials: true, ...options });
};

export default API;
